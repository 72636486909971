import styled from "styled-components";

import { TabsMenuStyledProps as Props } from "./TabsMenu.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { primary } = variables.fonts;
const { tablet } = CONSTANTS.BREAKPOINTS;

const TabsMenuStyled = styled.div<Props>`
  font-family: ${primary};

  .TabsMenu__bar {
    box-shadow: unset;
    border: 0.1rem solid var(--palette-gray-s0-l95);
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
  }

  .TabsMenu {
    &__tabs {
      &__item {
        min-width: 6rem;
      }
    }

    &--mobile {
      @media (min-width: ${tablet + 1}px) {
        display: none;
      }
    }

    &__bar {
      padding-bottom: 0.8rem;
    }
  }
`;

export default TabsMenuStyled;
