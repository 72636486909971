import styled from "styled-components";

import { HomeStyledProps as Props } from "./Home.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { tablet } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const HomeStyled = styled.div<Props>`
  font-family: ${primary};
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;
  padding-top: calc(var(--sizes-navbar-height) - env(safe-area-inset-top));

  @media (max-width: ${tablet}px) {
    padding-top: var(--sizes-navbar-height-mobile-without-search);
  }

  .Home {
    &__header {
      grid-column: fullBleed;

      @media (max-width: ${tablet}px) {
        padding-top: 2.4rem;
      }
    }

    &__main {
      flex: 1;
      display: flex;
      flex-direction: column;
      grid-column: content;

      @media (max-width: ${tablet}px) {
        grid-column: fullBleed;
      }
    }

    &__carousel {
      position: relative;

      & .Image__img {
        object-fit: contain;
      }
    }

    &__shortcuts {
      padding-top: 4rem;
      padding-bottom: 4.8rem;
      display: flex;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
        padding: 2.4rem 0.8rem;
      }
    }

    &__points {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 2rem;
      border-radius: 100px;
      gap: 0.5rem;

      &__text {
        display: flex;
        flex-direction: column;

        span:first-child {
          font-size: 1.6rem;
          font-weight: 600;
          color: var(--palette-orange-s89-l54);
        }
      }
    }

    &__repeatBtn {
      margin-right: 2.4rem;

      @media (max-width: ${tablet}px) {
        margin-right: 0;
        margin-bottom: 1.6rem;
      }
    }

    &__products {
      padding-bottom: 4.8rem;

      &__title {
        font-size: 2.4rem;
        font-weight: 600;
        color: var(--palette-black-s0-l10);
        padding-bottom: 3.2rem;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }

      &__grid {
        border-radius: 2.4rem;

        &:not(:last-child) {
          margin-bottom: 3.2rem;
        }
      }
    }

    &__footer {
      grid-column: fullBleed;
      padding-top: 3.2rem;
    }
  }
`;

export default HomeStyled;
