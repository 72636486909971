import styled from "styled-components";

import { BannerItemStyledProps as Props } from "./BannerItem.types";

const BannerItemStyled = styled.div<Props>`
  flex: 1;

  & .Image__img {
    border-radius: 2.4rem;
    overflow: hidden;
  }

  .BannerItem {
    &__image {
      padding: 0;
    }

    &__pointer {
      cursor: pointer;
    }
  }
`;

export default BannerItemStyled;
