import { useRouter } from "next/router";
import React from "react";

import Button from "../Button/Button";
import ProductCard from "../ProductCard/ProductCard";
import Styles from "./CategoryGrid.styles";
import { CategoryGridProps as Props } from "./CategoryGrid.types";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import useWindowSize from "hooks/useWindowSize";

import ArrowSvg from "../../../../public/assets/images/arrow-to-right.svg";

const { mobile } = CONSTANTS.BREAKPOINTS;

const CategoryGrid: React.FC<Props> = props => {
  const { className, category, showMoreButton = false, numberProducts } = props;
  const { push } = useRouter();
  const t = useI18n();
  const { width } = useWindowSize();

  const { name, products, categoryId } = category;
  const isMobile = width <= mobile;
  const maxProducts = isMobile ? 3 : 4;

  const handleClick = () => {
    push(`/categories/#${categoryId}`);
  };

  return (
    <Styles
      className={`CategoryGrid ${className}`}
      numberProducts={numberProducts}
      id={`${categoryId}`}
    >
      <div className="CategoryGrid__titles">
        <h2 className="CategoryGrid__title CategoryGrid__title-uppercase-first">
          {name}
        </h2>
        {showMoreButton && !isMobile ? (
          <Button
            type="FILLED"
            color="secondary"
            className="CategoryGrid__button"
            onClick={handleClick}
            isLoading={false}
          >
            {t.common.viewAll}
            <ArrowSvg />
          </Button>
        ) : null}
      </div>
      <div className="CategoryGrid__box">
        {products.map((product, index) => {
          if (index > maxProducts && showMoreButton) return null;
          return <ProductCard product={product} key={product.productId} />;
        })}
      </div>
      {showMoreButton && isMobile ? (
        <Button
          type="FILLED"
          className="CategoryGrid__button"
          onClick={handleClick}
          isLoading={false}
        >
          {t.common.viewAll}
          <ArrowSvg />
        </Button>
      ) : null}
    </Styles>
  );
};

CategoryGrid.defaultProps = {};

export default CategoryGrid;
