import { RepeatOrderPlaceholderStyled as Style } from "./RepeatOrder.styles";
import { RepeatOrderPlaceholderProps as Props } from "./RepeatOrder.types";

const RepeatOrderPlaceholder: React.FC<Props> = props => {
  const { className } = props;
  return (
    <Style className={`RepeatOrderPlaceholder ${className} loading-shine`} />
  );
};

export default RepeatOrderPlaceholder;
