import { useCountries } from "@simple/contexts";
import { useFetchRecommendedProducts } from "@simple/services";
import { ProductCard as ProductCardUI } from "artisn-ui-react";
import { useProductCard } from "artisn-ui-react";
import { events } from "artisn/analytics";
import { Product } from "artisn/types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import CrossaleModal from "../CrossaleModal/CrossaleModal";
import ProductBasePriceInfo from "../ProductBasePriceInfo/ProductBasePriceInfo";
import ProductModal from "../ProductModal/ProductModal";
import ProductCardPlaceholder from "./ProductCard.placeholder";
import Styles from "./ProductCard.styles";
import { ProductCardProps as Props } from "./ProductCard.types";
import ShoppingCartDrawer from "components/global/drawer/ShoppingCartDrawer/ShoppingCartDrawer";
import CONSTANTS from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import { goToProductDetail } from "utils/seo.utils";

const { Description, Image, Name } = ProductCardUI;
const { logProductImpression, logSelectProduct } = events.product;
const { WITH_PRODUCT_MODAL, WITH_CROSSALE_MODAL } = CONSTANTS.FEATURE_FLAGS;
const { WITH_CART_DRAWER, WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;

const ProductCard: React.FC<Props> = props => {
  const { product, icon, className } = props;
  const { carousel = false } = props;
  const enhancedProduct = !carousel
    ? { ...product, images: [product.images?.[0]] }
    : product;
  const { decimals } = useCountries().selectedCountry;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { totals, available } = useProductCard(product, { decimals });
  const { analyticsInitialized } = useAnalytics();
  const productCardRef = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState(false);
  const { data: recommendedProducts } = useFetchRecommendedProducts();
  const [openCrossale, setOpenCrossale] = useState(false);
  const [productId, setProductId] = useState("");
  const { additionalInfo } = product ?? {};
  const { basePrice } = additionalInfo ?? {};
  const { images } = enhancedProduct;
  const { bucket } = images?.[0] ?? {};

  const onOpenDrawer = () => {
    setOpened(true);
  };

  const callback = useCallback<IntersectionObserverCallback>(
    entries => {
      const [entry] = entries;
      if (!entry.isIntersecting || !analyticsInitialized) return;
      logProductImpression({
        product
      });
    },
    [analyticsInitialized, product]
  );

  const onClickModal = () => {
    setIsOpenModal(true);
  };

  const onSuccess = () => {
    if (WITH_PRODUCT_MODAL) {
      onClickModal();
    } else {
      goToProductDetail(enhancedProduct);
    }
  };

  const selectedProductHandler = (product: Product) => {
    setProductId(product.productId);
    if (WITH_PRODUCT_MODAL) {
      onClickModal();
    } else {
      goToProductDetail(product);
    }
    setOpenCrossale(false);
  };

  const onClickHandler = () => {
    setProductId(enhancedProduct.productId);
    if (WITH_CROSSALE_MODAL && recommendedProducts?.products.length) {
      setOpenCrossale(true);
      return;
    }
    logSelectProduct({
      product
    });
    if (WITH_PRODUCT_MODAL) {
      onClickModal();
    } else {
      goToProductDetail(enhancedProduct);
    }
  };

  useEffect(() => {
    const productCard = productCardRef.current;
    if (!productCard) return;

    const observer = new IntersectionObserver(callback, { threshold: 0.7 });
    observer.observe(productCard);

    return () => observer.unobserve(productCard);
  }, [callback]);

  return (
    <Styles
      ref={productCardRef}
      className={`ProductCard ${className}`}
      available={available}
    >
      <ProductCardUI
        className="ProductCard__card"
        product={enhancedProduct}
        placeholder={<ProductCardPlaceholder />}
        width="100%"
        height="max-content"
        onClick={onClickHandler}
      >
        {bucket ? (
          <Image
            className="ProductCard__image"
            infinite
            alt={enhancedProduct.name}
            controls={false}
          />
        ) : // TODO: add placeholder image(pending cx)
        null}
        <Name className="ProductCard__name" />
        <Description className="ProductCard__description" />
        <ProductBasePriceInfo
          className="ProductCard__price"
          productTotals={totals}
          basePrice={basePrice}
        />
        {icon}
      </ProductCardUI>
      {WITH_PRODUCT_MODAL ? (
        <ProductModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          productId={productId}
          onOpenDrawer={onOpenDrawer}
        />
      ) : null}
      {WITH_CART_DRAWER && WITH_PURCHASE ? (
        <ShoppingCartDrawer
          opened={opened}
          onClose={() => setOpened(prev => !prev)}
        />
      ) : null}
      {WITH_CROSSALE_MODAL &&
      recommendedProducts?.products.length &&
      WITH_PURCHASE ? (
        <CrossaleModal
          opened={openCrossale}
          onSuccess={onSuccess}
          products={recommendedProducts?.products}
          onClose={() => setOpenCrossale(false)}
          onClick={selectedProductHandler}
        />
      ) : null}
    </Styles>
  );
};

ProductCard.defaultProps = {
  className: ""
};

export default ProductCard;
