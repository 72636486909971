import styled, { css } from "styled-components";

import { CarouselBannerStyledProps as Props } from "./CarouselBanner.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { primary } = variables.fonts;
const { mobile, tablet } = CONSTANTS.BREAKPOINTS;

const sharedTextStyles = css`
  font-family: ${primary};
  font-weight: 700;
  text-transform: uppercase;
`;

const CarouselBannerStyled = styled.div<Props>`
  position: relative;

  .CarouselBanner {
    &__images {
      border-radius: 0;

      .Image {
        &__img {
          width: 100%;
          min-width: auto;
          height: auto;

          @media (max-width: ${tablet}px) {
            min-width: auto;
            height: 36rem;
          }

          @media (max-width: ${mobile}px) {
            min-width: auto;
            max-width: max-content;
            height: 18rem;
          }
        }
      }
    }

    &__content {
      height: 0;
      position: relative;
      width: 100%;
      bottom: 32rem;
      left: 4rem;
      width: 50%;

      @media (max-width: ${tablet}px) {
        bottom: 26rem;
      }

      @media (max-width: ${mobile}px) {
        bottom: 18rem;
      }
    }

    &__title {
      font-size: 4.2rem;
      ${sharedTextStyles}
      color: var(--palette-white);
      padding-bottom: 0.8rem;

      @media (max-width: ${tablet}px) {
        font-size: 2.4rem;
      }

      @media (max-width: ${mobile}px) {
        font-size: 2rem;
      }
    }

    &__description {
      font-size: 2rem;
      ${sharedTextStyles}
      color: var(--palette-yellow-s100-l50);

      @media (max-width: ${tablet}px) {
        font-size: 1.6rem;
      }

      @media (max-width: ${mobile}px) {
        font-size: 1rem;
        max-width: 16rem;
      }
    }

    &__btn {
      font-weight: 600;
      font-size: 1.6rem;
      margin-top: 1.6rem;

      @media (max-width: ${mobile}px) {
        font-size: 1.2rem;
        width: auto;
      }
    }
  }
`;

export default CarouselBannerStyled;
