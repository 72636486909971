import styled from "styled-components";

import { BannerListStyledProps as Props } from "./BannerList.types";
import { BannerListPlaceholderStyledProps as PlaceholderProps } from "./BannerList.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const BannerListStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .BannerList {
    &__item {
      &:first-child {
        margin-right: 3.2rem;
      }

      @media (max-width: ${tablet}px) {
        &:first-child {
          margin-right: 0;
          margin-bottom: 3.2rem;
        }
      }
    }
  }
`;

export const BannerListPlaceholderStyled = styled.div<PlaceholderProps>`
  flex: 1;
  min-height: 20rem;
  border-radius: 2.4rem;
  width: 100%;

  .BannerListPlaceholder {
  }
`;

export default BannerListStyled;
