import styled from "styled-components";

import { ClubButtonStyledProps as Props } from "./ClubButton.types";
import variables from "styles/util/variables";

const { primary } = variables.fonts;

const ClubButtonStyled = styled.div<Props>`
  font-family: ${primary};
  background-color: var(--palette-white);
  border-radius: 10rem;
  min-width: 24rem;
  transition: transform 0.5s ease 0s;

  &:hover {
    transform: scale(1.06);
  }

  .ClubButton {
    &__container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.4rem 1.6rem;
    }

    &__title {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.8rem;
      color: var(--palette-black-s0-l10);
    }

    &__subTitle {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-primary);
    }

    &__arrow {
      path {
        fill: var(--palette-primary);
      }
    }
  }
`;

export default ClubButtonStyled;
