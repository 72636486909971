import styled from "styled-components";

import { RepeatOrderStyledProps as Props } from "./RepeatOrder.types";
import { RepeatOrderPlaceholderStyledProps as PlaceholdersProps } from "./RepeatOrder.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { mobile } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const RepeatOrderStyled = styled.div<Props>`
  font-family: ${primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  background-color: var(--palette-white);
  border-radius: 10rem;

  @media (max-width: ${mobile}px) {
    padding: 0.4rem 0.8rem;
  }

  .RepeatOrder {
    &__labels {
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;

      @media (max-width: ${mobile}px) {
        padding-right: 0;
        padding-left: 0.4rem;
        padding-top: 0.8rem;
      }
    }

    &__date {
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--palette-black-s0-l10);

      @media (max-width: ${mobile}px) {
        font-size: 1.2rem;
      }
    }

    &__title {
      font-size: 1.2rem;
      font-weight: 400;
      color: var(--palette-gray-s0-l35);
      white-space: nowrap;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 2rem;

      &__title {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--palette-white);

        @media (max-width: ${mobile}px) {
          font-size: 1.2rem;
          white-space: nowrap;
        }
      }

      &__svg {
        path {
          fill: var(--palette-white);
        }
      }

      @media (max-width: ${mobile}px) {
        padding: 1rem;
      }
    }
  }
`;

export const RepeatOrderPlaceholderStyled = styled.div<PlaceholdersProps>`
  width: 100%;
  max-width: 36.8rem;
  height: 5.6rem;
  border-radius: 10rem;
`;

export default RepeatOrderStyled;
