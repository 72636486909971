import { useFetchBanners } from "@simple/services";
import { Carousel as CarouselUI } from "artisn-ui-react";
import React, { useState } from "react";

import Styles from "./Carousel.styles";
import { CarouselProps as Props } from "./Carousel.types";
import CarouselBanner from "./CarouselBanner/CarouselBanner";

const Carousel: React.FC<Props> = props => {
  const { className, bannerCategoryName } = props;
  const { data: banners, status } = useFetchBanners(bannerCategoryName);
  const [counter, setCounter] = useState(1);

  const carouselHandler = (value: number) => {
    setCounter(value);
  };

  const renderCarousel = () => {
    // TODO: ADD LOADING
    if (status === "idle" || status === "loading") return null;
    // TODO: ADD ERROR
    if (status === "error") return null;

    if (status === "success") {
      const bannersLength = banners?.length;
      if (!bannersLength) {
        console.warn(
          "Warning: Unable to display. There are no images to display on the carousel "
        );
        return null;
      }

      return (
        <>
          <CarouselUI
            {...props}
            onChange={carouselHandler}
            className="Carousel__carousel"
          >
            {banners.map(banner => {
              return <CarouselBanner key={banner.image_key} banner={banner} />;
            })}
          </CarouselUI>
          <div className="Carousel__counter">
            {counter}/{bannersLength}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <Styles className={`Carousel ${className}`}>
      <section className="Carousel__content">{renderCarousel()}</section>
    </Styles>
  );
};

Carousel.defaultProps = {
  className: ""
};

export default Carousel;
