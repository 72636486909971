import React from "react";

import { BannerListPlaceholderStyled as Styles } from "./BannerList.styles";
import { BannerListPlaceholderProps as Props } from "./BannerList.types";

const BannerListPlaceholder: React.FC<Props> = props => {
  const { className } = props;

  return (
    <Styles className={`BannerListPlaceholder ${className} loading-shine`} />
  );
};

BannerListPlaceholder.defaultProps = {};

export default BannerListPlaceholder;
