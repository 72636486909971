import styled from "styled-components";

import { CarouselStyledProps as Props } from "./Carousel.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const CarouselStyled = styled.div<Props>`
  font-family: ${primary};
  width: 100%;
  background-color: var(--palette-orange-s89-l54);

  .Carousel__inner-item {
    border-radius: unset;
  }

  .Carousel {
    &__content {
      grid-column: content;
      position: relative;
      height: auto;

      @media (max-width: ${tablet}px) {
        grid-column: fullBleed;
        height: 36rem;
      }

      @media (max-width: ${mobile}px) {
        grid-column: fullBleed;
        height: 18rem;
      }
    }

    &__carousel {
      .Pagination {
        right: auto;
        left: 1.6rem;

        &__dot {
          background-color: var(--palette-orange-s92-l95);
          width: 0.6rem;
          height: 0.6rem;

          &--active {
            background-color: var(--palette-yellow-s100-l50);
          }

          :not(:first-child) {
            margin-left: 0.8rem;
          }
        }

        @media (max-width: ${tablet}px) {
          right: 0;
          left: 0;
        }
      }

      .Controls {
        &__arrow {
          background-color: unset;

          &--left {
            left: 1.6rem;
            padding: 0;
          }

          &--right {
            right: 1.6rem;
            padding: 0;
          }
        }

        &__icon path {
          fill: var(--palette-white);
        }
      }
    }

    &__counter {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--palette-black-s0-l10);
      width: 3.6rem;
      height: 2.4rem;
      color: var(--palette-white);
      position: absolute;
      top: 1.6rem;
      right: 0.8rem;
      border-radius: 10rem;
      z-index: 1;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }
`;

export default CarouselStyled;
