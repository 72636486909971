import { useShoppingCart } from "@simple/contexts";
import { fetchRebuildCart } from "@simple/services";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import React, { useState } from "react";

import Styles from "./RepeatOrder.styles";
import { RepeatOrderProps as Props } from "./RepeatOrder.types";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";

import RepeatSvg from "../../../../public/assets/images/repeat.svg";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_CART_DRAWER } = FEATURE_FLAGS;

const RepeatOrder: React.FC<Props> = props => {
  const { className, order } = props;
  const t = useI18n();
  const { shoppingCart: currentShoppingCart } = useShoppingCart();
  const { id: currentShoppingCartId } = currentShoppingCart ?? {};
  const [, setOpened] = useState(false);
  const { push } = useRouter();

  const { orderDate, id, seq_id } = order ?? {};

  const repeatOrder = async () => {
    await fetchRebuildCart(id, currentShoppingCartId);
    if (WITH_CART_DRAWER) {
      setOpened(true);
    } else {
      push("/cart");
    }
  };

  return (
    <Styles className={`RepeatOrder ${className}`}>
      <div className="RepeatOrder__labels">
        <p className="RepeatOrder__date">
          {dayjs(orderDate).format("DD/MM/YYYY")}
        </p>
        <p className="RepeatOrder__title">
          {t.repeatOrder.order}: {seq_id}
        </p>
      </div>
      <Button className="RepeatOrder__btn" onClick={repeatOrder}>
        <p className="RepeatOrder__btn__title">{t.repeatOrder.lastOrder}</p>
        <RepeatSvg className="RepeatOrder__btn__svg" />
      </Button>
    </Styles>
  );
};

RepeatOrder.defaultProps = {};

export default RepeatOrder;
